import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["keyboardNavClick"];

  clickElement() {
    if (!this.keyboardNavClickTarget.classList.contains("disabled-option")) {
      event.preventDefault();
      this.keyboardNavClickTarget.click();
    }
  }
}
