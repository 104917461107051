import { Controller } from "stimulus";

export default class Filter extends Controller {
  static targets = ["item"];

  filterItems(event) {
    const re = new RegExp(event.target.value, "i");

    this.itemTargets.forEach((item) => {
      if (item.dataset.filterTags.match(re)) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }
}
