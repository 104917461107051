import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      new Swiper(this.element, {
        slidesPerView: 1.3,
        centeredSlides: true,
        spaceBetween: 24,
        breakpoints: {
          1000: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          1210: {
            slidesPerView: 3,
            centeredSlides: false,
          },
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        observer: true,
        observeParents: true,
        spaceBetween: 20,
        initialSlide: 1,
      });
    }, 250);
  }
}
