import { Controller } from "stimulus";
import { ZipPlanningMap } from "../utils/zip_planning_map";
import wkx from "wkx";

export default class extends Controller {
  static targets = [
    "map",
    "mapOrigin",
    "zipInput",
    "zipData",
    "zipGroupDaysData",
    "zipGroupDisplayCheckbox",
  ];

  connect() {
    this.zips = this.readZipData();
    this.mapOrigin = this.readMapOrigin();
    this.zipGroupDays = this._readZipGroupDaysData();
    this.load();
    this.refreshMap();
    // NOTE: adding a manual timeout to reload the selected zips.. According to the docs,
    //       Stimulus should be calling `connect` after Turbo has reloaded the DOM
    //       but for some reason at this point the zipInputTarget is still empty
    const self = this;
    setTimeout(() => {
      this.refreshMap();
    }, 250);
  }

  load() {
    const selected = this.zipGroupDisplayCheckboxTargets.filter(
      (checkbox) => checkbox.checked,
    );
    const selectedIds = selected.map((checkbox) => parseInt(checkbox.value));
    const displayableZipGroupDays = this.zipGroupDays.filter((zipGroupDay) =>
      selectedIds.includes(zipGroupDay.zipGroupDeliveryDayId),
    );
    this.map = new ZipPlanningMap({
      element: this.mapTarget,
      origin: { lat: this.mapOrigin.lat, lng: this.mapOrigin.lng },
      zips: this.zips,
      zipGroupDays: displayableZipGroupDays,
    });
    this.map.render();
    this.map.on("selectedZipsChange", this.onSelectedZipsChange.bind(this));
  }

  onSelectedZipsChange(selectedZips) {
    this.zipInputTarget.value = selectedZips.map((z) => z.code).join(", ");
  }

  readMapOrigin() {
    return JSON.parse(this.mapOriginTarget.value);
  }

  readZipData() {
    return JSON.parse(this.zipDataTarget.value).map((rawZip) => {
      const zip = JSON.parse(rawZip);
      return {
        code: zip.code,
        centroid: zip.centroid ? wkx.Geometry.parse(zip.centroid) : null,
        hull: zip.hull ? wkx.Geometry.parse(zip.hull) : null,
      };
    });
  }

  refreshMap() {
    let mapZips = this.map.selectedZips.zips.map((z) => z.code),
      inputZips = this._getInputCodes();

    let deselectZips = mapZips.filter((mz) => !inputZips.includes(mz)),
      selectZips = inputZips.filter((iz) => !mapZips.includes(iz));

    deselectZips && this.map.deselectZipCodes(deselectZips);
    selectZips && this.map.selectZipCodes(selectZips);
  }

  toggleDisplayableZipGroups() {
    this.load();
  }

  selectAllOfZipGroup(e) {
    const zipGroupDay = this.zipGroupDays.find(
      (day) =>
        day.zipGroupDeliveryDayId ===
        parseInt(e.target.getAttribute("data-zip-group-delivery-day-id")),
    );
    if (e.target.checked) {
      this.map.selectZipCodes(zipGroupDay.zipCodes);
    } else {
      this.map.deselectZipCodes(zipGroupDay.zipCodes);
    }
    this.onSelectedZipsChange(this.map.selectedZips.zips);
  }

  _readZipGroupDaysData() {
    return JSON.parse(this.zipGroupDaysDataTarget.value).map((raw) =>
      JSON.parse(raw),
    );
  }

  _getInputCodes() {
    return this.zipInputTarget.value
      .replace(/ /g, "")
      .split(",")
      .filter((z) => z.length > 0);
  }
}
