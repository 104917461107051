import { Controller } from "stimulus";
export default class extends Controller {
  static values = { minPoints: Number };
  static targets = [
    "validationErrorNotice",
    "submitDisabledNotice",
    "bannerNotice",
    "formSubmit",
  ];
  connect() {
    this.checkValidations();
  }

  validationErrorNoticeTargetConnected(connectedValidationTarget) {
    this.checkValidations();
  }

  checkValidations() {
    const visibleNotices = this.validationErrorNoticeTargets.filter(
      (target) => {
        return target.offsetParent !== null;
      },
    );
    if (visibleNotices.length > 0) {
      this._disableFormSubmission();
      if (this.hasBannerNoticeTarget) {
        this.bannerNoticeTarget.classList.remove("hidden");
      }
    } else {
      this._enableFormSubmission();
      if (this.hasBannerNoticeTarget) {
        this.bannerNoticeTarget.classList.add("hidden");
      }
    }
  }

  _enableFormSubmission() {
    for (const formSubmitTarget of this.formSubmitTargets) {
      formSubmitTarget.removeAttribute("disabled", "disabled");
    }
    if (this.hasSubmitDisabledNoticeTarget) {
      this.submitDisabledNoticeTarget.classList.add("hidden");
    }
  }

  _disableFormSubmission() {
    for (const formSubmitTarget of this.formSubmitTargets) {
      formSubmitTarget.setAttribute("disabled", "disabled");
    }
    if (this.hasSubmitDisabledNoticeTarget) {
      this.submitDisabledNoticeTarget.classList.remove("hidden");
    }
  }
}
