import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  update(event) {
    if (event.target.checked) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.containerTarget.classList.add("hidden");
    }
  }
}
