import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["invalidMessage", "submit"];

  validateFileSize(event) {
    let file = event.target.files[0];
    const maxFileSizeInBytes = 1048576;
    let messageHolder = this.invalidMessageTarget;
    let submitButton = this.submitTarget;
    if (file.size > maxFileSizeInBytes) {
      messageHolder.classList.remove("hidden");
      submitButton.disabled = true;
    } else {
      messageHolder.classList.add("hidden");
      submitButton.disabled = false;
    }
  }
}
