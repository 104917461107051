import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["turboFrame"];

  static values = { orderNumber: String };

  updateFrame(e) {
    let frame = this.turboFrameTarget;
    let zipCode = e.target.value;
    if (zipCode.length == 5) {
      frame.src = `/orders/${this.orderNumberValue}/delivery_days?zip_code=${zipCode}`;
      frame.reload;
    }
  }
}
