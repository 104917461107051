import { debounce } from "../utils/debounce";
import { WaldenMap } from "../utils/walden_map";
import { EventEmitter } from "eventemitter3";

class SelectedZips {
  constructor(args) {
    this.emitter = args.emitter;
    this.zips = [];
    this.emitSelectedZipChange = debounce(() => {
      this.emitter.emit("selectedZipsChange", this.zips);
    }, 5);
  }

  add(zip) {
    this.zips.push(zip);
  }

  addAndEmitZipChanges(zip) {
    this.add(zip);
    this.emitSelectedZipChange();
  }

  remove(zip) {
    const index = this.zips
      .map(function (z) {
        return z.code;
      })
      .indexOf(zip.code);
    this.zips.splice(index, 1);
  }

  removeAndEmitZipChanges(zip) {
    this.remove(zip);
    this.emitSelectedZipChange();
  }
}

export class ZipPlanningMap {
  constructor(args) {
    this.zips = args.zips;
    this.map = new WaldenMap({ element: args.element, origin: args.origin });
    this.emitter = new EventEmitter();
    this.selectedZips = new SelectedZips({ emitter: this.emitter });
    this.zipGroupDays = args.zipGroupDays;
    this.colors = [
      "#1e00a5",
      "#2243B6",
      "#0066FF",
      "#9DE093",
      "#3AA655",
      "#00CCCC",
      "#008080",
      "#47ABCC",
      "#FFFF99",
      "#FFEB00",
      "#ECB176",
      "#9E5B40",
      "#FF681F",
      "#FF8833",
      "#733380",
      "#C154C1",
      "#FC74FD",
      "#f88fb2",
      "#00468C",
      "#0066CC",
      "#bfd8c3",
      "#3c5743",
      "#c7f5f5",
      "#5ea5a5",
      "#2094bb",
      "#dbdb25",
      "#b9aa03",
      "#c47425",
      "#702d12",
      "#fcece4",
      "#a79385",
      "#4d0b5a",
      "#e901e9",
      "#b98eb9",
      "#665233",
      "#837050",
      "#9c3434",
      "#8B8680",
      "#C8C8CD",
    ];
  }

  render() {
    this.map.render();
    this._showZipCodes();
    this._showZipGroups();
  }

  on(event, callback) {
    this.emitter.on(event, callback);
  }

  selectZipCodes(codes) {
    const zipsToSelect = this.zips.filter((z) => codes.includes(z.code));
    for (const zip of zipsToSelect) {
      this._onZipSelect(zip, false, false);
    }
  }

  deselectZipCodes(codes) {
    const zipsToDeselect = this.zips.filter((z) => codes.includes(z.code));
    for (const zip of zipsToDeselect) {
      this._deselectZipOnMap(zip);
      this.selectedZips.remove(zip);
    }
  }

  _showZipCodes() {
    if (this.zips) {
      for (const zip of this.zips) {
        const code = zip.code;
        const hull = zip.hull;
        if (hull) {
          const paths = hull.exteriorRing.map((point) => {
            return { lat: point.y, lng: point.x };
          });
          this.map.createPolygon(code, paths);
          const _this = this;
          this.map.addListener("polygon", code, "click", function () {
            _this._onZipSelect(zip, true);
            // self.sendAction('zipcodeSelected', zip);
          });
        }
      }
    }
  }

  _showZipGroups() {
    const map = this.map;
    for (const grpDay of this.zipGroupDays) {
      if (grpDay.zipCodes.length) {
        const grp_color = this.colors[parseInt(grpDay.dayOfMonth)];
        for (const code of grpDay.zipCodes) {
          const poly = map.polygons[code];
          if (poly) {
            map.updatePolygonColor(code, grp_color, true);
          }
        }
        const grpZips = this.zips.filter(
          (zip) => grpDay.zipCodes.indexOf(zip.code) != -1,
        );
        const cent = this._calculateCentroid(grpZips);
        map.createMarker(
          `${grpDay.dayOfMonth} - ${grpDay.zipGroup.codename}`,
          { lat: cent.y, lng: cent.x },
          grp_color,
          null,
          "group",
          `${grpDay.dayOfMonth} - ${grpDay.zipGroup.codename}`,
        );
      }
    }
  }

  _calculateCentroid(zips) {
    const withCentroids = zips.filter((zip) => zip.centroid);
    const sumValues = withCentroids.reduce(
      function (acc, zip) {
        const x_sum = acc.x + zip.centroid.x;
        const y_sum = acc.y + zip.centroid.y;
        return { x: x_sum, y: y_sum };
      },
      { x: 0, y: 0 },
    );
    return {
      x: sumValues.x / withCentroids.length,
      y: sumValues.y / withCentroids.length,
    };
  }

  _onZipSelect(zip, clicked, zoom) {
    const code = zip.code;
    let found = false;
    for (const zp of this.selectedZips.zips) {
      if (zp.code === code) {
        found = true;
        break;
      }
    }
    if (found) {
      if (clicked) {
        this._deselectZipOnMap(zip);
        this.selectedZips.removeAndEmitZipChanges(zip);
      }
      return;
    } else {
      this._selectZipOnMap(zip);
      if (clicked) {
        this.selectedZips.addAndEmitZipChanges(zip);
      } else {
        this.selectedZips.add(zip);
      }
      if (zoom && zip.centroid) {
        this.map.zoomTo({ lat: zip.centroid.y, lng: zip.centroid.x });
      }
    }
  }

  _selectZipOnMap(zip) {
    const code = zip.code;
    const poly = this.map.polygons[code];
    if (zip.grpId) {
      zip.set("color", poly.poly.fillColor);
    }
    this.map.updatePolygonColor(code, this.map.polyColors.selected);
    this._showZipcodePin(zip);
  }

  _deselectZipOnMap(zip, reset) {
    if (reset === true) {
      this.map.updatePolygonColor(zip.code, this.map.polyColors.default, true); // return to default color
    } else {
      this.map.updatePolygonColor(zip.code); // return to original color
    }
    this._hideZipcodePin(zip);
  }

  _showZipcodePin(zip) {
    if (zip.centroid) {
      const centPos = { lat: zip.centroid.y, lng: zip.centroid.x };
      this.map.addPolygonLabel(zip.code, centPos, zip.code);
    }
  }

  _hideZipcodePin(zip) {
    this.map.removePolygonLabel(zip.code);
  }
}
