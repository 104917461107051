import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "dietaryRestriction",
    "cutGroup",
    "dietaryRestrictionNotice",
  ];

  dietaryRestrictionNoticeTargetConnected(
    connectedDietaryRestrictionNoticeTarget,
  ) {
    this.toggleDietaryRestrictionNotice();
  }

  toggleDietaryRestrictionNotice() {
    let cutGroupsWithIngredients = this._cutGroupsWithAnyIngredients();
    let checkedRestrictions = this._checkedDietaryRestrictions();
    let checkedCutGroupsWithMatchingIngredients =
      this._checkedCutGroupsWithMatchingIngredients(
        cutGroupsWithIngredients,
        checkedRestrictions,
      );
    if (checkedCutGroupsWithMatchingIngredients.length > 0) {
      this.dietaryRestrictionNoticeTarget.classList.remove("hidden");
      this._toggleDietaryRestrictionTooltips(
        cutGroupsWithIngredients,
        checkedRestrictions,
      );
    } else if (this.hasDietaryRestrictionNoticeTarget) {
      this.dietaryRestrictionNoticeTarget.classList.add("hidden");
      let noticeTargetParent = this.dietaryRestrictionNoticeTarget.parentNode;
      let tooltips = Array.from(
        noticeTargetParent.querySelectorAll(".tooltip-container"),
      );
      tooltips.map((el) => el.classList.add("hidden"));
    }
  }

  _toggleDietaryRestrictionTooltips(
    cutGroupsWithIngredients,
    checkedDietaryRestrictions,
  ) {
    for (const cutGroup of cutGroupsWithIngredients) {
      let cutGroupParent = cutGroup.parentNode;
      let tooltip = cutGroupParent.querySelector(".tooltip-container");
      let ingredients = cutGroup.dataset.ingredients.split(",");
      if (tooltip !== null) {
        if (
          cutGroup.checked &&
          this._doArraysShareElements(ingredients, checkedDietaryRestrictions)
        ) {
          tooltip.classList.remove("hidden");
        } else {
          tooltip.classList.add("hidden");
        }
      }
    }
  }

  _cutGroupsWithAnyIngredients() {
    let arr = this.cutGroupTargets;
    let withIngredients = arr.filter((el) => el.dataset.ingredients !== "");
    return withIngredients;
  }

  _checkedCutGroupsWithMatchingIngredients(cutGroups, checkedRestrictions) {
    let matchingCutGroups = [];
    let checkedCutGroups = cutGroups.filter((el) => el.checked);
    checkedCutGroups.filter((el) => {
      let ingredients = el.dataset.ingredients.split(",");
      if (this._doArraysShareElements(ingredients, checkedRestrictions)) {
        matchingCutGroups.push(el);
      }
    });
    return matchingCutGroups;
  }

  _checkedDietaryRestrictions() {
    let arr = this.dietaryRestrictionTargets;
    let checked = arr.filter((el) => el.checked == true);
    let checkedDietaryRestrictions = checked.map((el) => el.dataset.ingredient);
    return checkedDietaryRestrictions;
  }

  _doArraysShareElements(arr1, arr2) {
    for (const i in arr1) {
      if (arr2.indexOf(arr1[i]) !== -1) {
        return true;
      }
    }
    return false;
  }
}
