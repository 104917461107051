import { Controller } from "stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static values = {
    cdpTrackerToken: String,
    cdpTrackerIdentity: String,
  };

  static outlets = ["plans--share-cards", "plans--cut-group-preferences"];

  static targets = [
    "meatType",
    "shareCard",
    "shareCardDescription",
    "shareSelectForm",
    "shareCardsContainer",
    "shareSelect",
    "frequencySelect",
    "cutPortionSelect",
    "pricingHeader",
    "cutPortionHelper",
    "cutPortionsSection",
    "legacyDiscountMessage",
    "shellfishInclusionRadioButton",
  ];

  // NOTE: we would want to check this on connect, but instead we do it when the outlet
  //       gets connected, because we can't be sure that Stimulus will connect that outlet
  //       and start that controller when this controller gets set up.
  plansCutGroupPreferencesOutletConnected() {
    // NOTE: We refresh the active share on load, in case the plan has an invalid meat type
    //       combo selected. This will then lead the view to update the plan to the next-most
    //       compatible plan.
    this.refreshActiveShareSelectForm(null);
  }

  // NOTE: this is called when a share card in the share selection carousel is clicked
  switchSelectedShareType(event) {
    // NOTE: event.target is the innermost element clicked
    //       event.currentTarget is the target on which the listener is installed
    let selectedShareType = event.currentTarget.dataset.shareType;
    this.plansShareCardsOutlet.toggleActiveShareCards(selectedShareType);
    this._switchSelectedShareTypeTo(
      selectedShareType,
      this._shareSelectFormGroupsBasedOnCurrentSelections(selectedShareType),
    );
  }

  // NOTE: Called when user changes selected share in select field
  switchSelectedShare(event) {
    this._setMinWeight(event.currentTarget);
    let selectedShareType = event.currentTarget.dataset.shareType;
    const selectTargetGroups =
      this._shareSelectFormGroupsBasedOnCurrentSelections(selectedShareType);
    selectTargetGroups
      .filter((selectTargetGroup) => selectTargetGroup.active)
      .forEach(({ shareSelectTarget }) => {
        this._updatePricingHeaderText(shareSelectTarget);
        this._updateCutPortionHelperText(shareSelectTarget);
      });
    this._updateShareDescriptions(selectTargetGroups);
  }

  onCutPortionChange(_event) {
    const shareSelectTarget = this.shareSelectTargets.filter(
      (target) => target.hasAttribute("disabled") == false,
    )[0];
    this._updateCutPortionHelperText(shareSelectTarget);
  }

  onFrequencyChange(_event) {
    const shareSelectTarget = this.shareSelectTargets.filter(
      (target) => target.hasAttribute("disabled") == false,
    )[0];
    this._updatePricingHeaderText(shareSelectTarget);
    this._updateCutPortionHelperText(shareSelectTarget);
  }

  onSpecialsOnlyFrequencyChange(_event) {
    const frequencyText =
      this.frequencySelectTarget.options[
        this.frequencySelectTarget.selectedIndex
      ].text;
    this.pricingHeaderTarget.innerText = `${frequencyText}`;
  }

  // NOTE: Called when user changes clicks shellfish included / not
  //       and when user selects meat type
  refreshActiveShareSelectForm(_event) {
    const initialActiveShareSelectTarget = this.shareSelectTargets.filter(
      (shareSelectTarget) => !shareSelectTarget.disabled,
    )[0];
    const selectTargetGroups =
      this._shareSelectFormGroupsBasedOnCurrentSelections(
        initialActiveShareSelectTarget.dataset.shareType,
      );
    const activeSelectGroup = selectTargetGroups.filter(
      ({ active, matchesMeatCombos, matchesShellfish }) => {
        return active && matchesMeatCombos && matchesShellfish;
      },
    )[0];

    if (activeSelectGroup == undefined) {
      this.plansShareCardsOutlet.toggleActiveShareCards(null);
    } else if (
      activeSelectGroup.shareSelectTarget != initialActiveShareSelectTarget
    ) {
      this._selectClosestShareOnMinWeight(activeSelectGroup.shareSelectTarget);
      this.plansShareCardsOutlet.toggleActiveShareCards(
        activeSelectGroup.shareSelectTarget.dataset.shareType,
      );
      this._switchSelectedShareTypeTo(
        activeSelectGroup.shareSelectTarget.dataset.shareType,
        selectTargetGroups,
      );
    }
    this.plansShareCardsOutlet.toggleApplicableShares(
      this._activeShareTypes(selectTargetGroups),
    );
  }

  _switchSelectedShareTypeTo(selectedShareType, selectTargetGroups) {
    for (const selectTargetGroup of selectTargetGroups) {
      const { shareSelectTarget, shareSelectFormTarget } = selectTargetGroup;

      if (selectTargetGroup.active) {
        this._enableAllNestedInputs(shareSelectFormTarget);
        this._showOrHideCutPortionsSelection(shareSelectTarget);
        this._toggleCutGroupPreferencesSection(shareSelectTarget);
        this._updatePricingHeaderText(shareSelectTarget);
        this._updateCutPortionHelperText(shareSelectTarget);
        this._showOrHideLegacyDiscountText(shareSelectTarget);
      } else {
        this._disableAllNestedInputs(shareSelectFormTarget);
      }
    }
    this._updateShareDescriptions(selectTargetGroups);
    this._trackOnCustomerDataPlatform({
      "Share Type": selectedShareType,
    });
  }

  _setMinWeight(shareSelectTarget) {
    let shareMinWeight =
      shareSelectTarget.options[shareSelectTarget.selectedIndex].dataset
        .minWeight;
    this.shareCardsContainerTarget.setAttribute(
      "data-share-min-weight",
      shareMinWeight,
    );
  }

  _toggleCutGroupPreferencesSection(shareSelectTarget) {
    const target = shareSelectTarget.options[shareSelectTarget.selectedIndex];
    this.plansCutGroupPreferencesOutlet.toggleCutGroupsConfigSection(target);
  }

  _shareSelectFormGroupsBasedOnCurrentSelections(selectedShareType) {
    return this.shareSelectTargets.reduce((acc, shareSelectTarget) => {
      const shareSelectFormTarget = shareSelectTarget.closest(
        'div[data-plans--share-selection-target="shareSelectForm"]',
      );

      const matchesMeatCombos = this._selectedMeatTypesMatchesMeatCombo(
        shareSelectFormTarget.dataset.meatCombos,
      );

      const matchesShellfish = this._selectedShellfishInclusionMatches(
        shareSelectFormTarget.dataset.shellfish,
      );

      const active =
        shareSelectTarget.dataset.shareType == selectedShareType &&
        matchesMeatCombos &&
        matchesShellfish;

      acc.push({
        shareSelectTarget: shareSelectTarget,
        shareSelectFormTarget: shareSelectFormTarget,
        active: active,
        matchesMeatCombos: matchesMeatCombos,
        matchesShellfish: matchesShellfish,
      });

      return acc;
    }, []);
  }

  _selectClosestShareOnMinWeight(shareSelectTarget) {
    let options = Array.from(shareSelectTarget.options);
    let idealWeight = this.shareCardsContainerTarget.dataset.shareMinWeight;
    let closestWeight = options.reduce((previous, current) => {
      return Math.abs(current.dataset.minWeight - idealWeight) <
        Math.abs(previous.dataset.minWeight - idealWeight)
        ? current
        : previous;
    });
    shareSelectTarget.selectedIndex = options.indexOf(closestWeight);
  }

  _enableAllNestedInputs(target) {
    for (const input of target.getElementsByTagName("input")) {
      if (/quantity/.test(input.name)) {
        input.setAttribute("value", 1);
      }

      input.removeAttribute("disabled");
      input.classList.remove("hidden");
    }
    for (const input of target.getElementsByTagName("select")) {
      input.removeAttribute("disabled");
      input.classList.remove("hidden");
    }
  }

  _disableAllNestedInputs(target) {
    for (const input of target.getElementsByTagName("input")) {
      if (/quantity/.test(input.name)) {
        input.setAttribute("value", 0);
      }

      input.setAttribute("disabled", "disabled");
      input.classList.add("hidden");
    }
    for (const input of target.getElementsByTagName("select")) {
      input.setAttribute("disabled", "disabled");
      input.classList.add("hidden");
    }
  }

  _updatePricingHeaderText(shareSelectTarget) {
    const selectedShareText =
      this._selectedOrDefaultShareText(shareSelectTarget);
    const price = selectedShareText.split("$")[1];
    const frequencyText =
      this.frequencySelectTarget.options[
        this.frequencySelectTarget.selectedIndex
      ].text;
    this.pricingHeaderTarget.innerText = `$${price} ${frequencyText}`;
  }

  _selectedOrDefaultShareText(shareSelectTarget) {
    const selectedShareOption =
      shareSelectTarget.options[shareSelectTarget.selectedIndex];
    if (selectedShareOption.value == "") {
      for (const option of shareSelectTarget.options) {
        if (option.value != "") {
          return option.text;
        }
      }
    } else {
      return selectedShareOption.text;
    }
  }

  _updateCutPortionHelperText(shareSelectTarget) {
    if (this.hasCutPortionSelectTarget) {
      const selectedShareOption =
        shareSelectTarget.options[shareSelectTarget.selectedIndex];
      const minWeight = selectedShareOption.dataset.minWeight;
      const frequencyText =
        this.frequencySelectTarget.options[
          this.frequencySelectTarget.selectedIndex
        ].text;
      const numberOfPeople =
        this.cutPortionSelectTarget.options[
          this.cutPortionSelectTarget.selectedIndex
        ].text;
      // each person is estimated to eat 0.5 lbs per meal
      const numberOfMeals = Math.floor(minWeight / (numberOfPeople * 0.5));
      this.cutPortionHelperTarget.innerText = `${numberOfMeals} Meals Serving ${numberOfPeople} People ${frequencyText}`;
    }
  }

  _showOrHideCutPortionsSelection(shareSelectTarget) {
    if (this.hasCutPortionsSectionTarget) {
      if (shareSelectTarget.dataset.showCutPortions == "true") {
        this.cutPortionsSectionTarget.classList.remove("hidden");
        this._enableAllNestedInputs(this.cutPortionsSectionTarget);
      } else {
        this.cutPortionsSectionTarget.classList.add("hidden");
        this._disableAllNestedInputs(this.cutPortionsSectionTarget);
      }
    }
  }

  _showOrHideLegacyDiscountText(shareSelectTarget) {
    if (shareSelectTarget.dataset.showLegacyDiscount == "true") {
      this.legacyDiscountMessageTarget.classList.remove("hidden");
    } else {
      this.legacyDiscountMessageTarget.classList.add("hidden");
    }
  }

  _updateShareDescriptions(selectTargetGroups) {
    for (const {
      active,
      matchesMeatCombos,
      matchesShellfish,
      shareSelectTarget,
    } of selectTargetGroups) {
      if (matchesMeatCombos && matchesShellfish) {
        if (!active) {
          this._selectClosestShareOnMinWeight(shareSelectTarget);
        }
        this._updateShareDescription(shareSelectTarget);
      }
    }
  }

  _updateShareDescription(shareSelectTarget) {
    for (const shareCardDescriptionTarget of this.shareCardDescriptionTargets) {
      if (
        shareCardDescriptionTarget.dataset.shareType ==
        shareSelectTarget.dataset.shareType
      ) {
        const selectedShare =
          shareSelectTarget.options[shareSelectTarget.selectedIndex];
        shareCardDescriptionTarget.innerHTML =
          selectedShare.dataset.description;
      }
    }
  }

  _selectedMeatTypesMatchesMeatCombo(meatCombo) {
    let selectedMeatTypeNames = new Set(
      this.meatTypeTargets
        .filter((target) => target.checked)
        .map((target) => target.dataset.meatType),
    );
    let optionMeatCombos = JSON.parse(meatCombo).map((arr) => new Set(arr));

    return optionMeatCombos.some((combo) => {
      return (
        selectedMeatTypeNames.size === combo.size &&
        [...selectedMeatTypeNames].every((meatType) => combo.has(meatType))
      );
    });
  }

  _selectedShellfishInclusionMatches(formsShellfishValue) {
    if (formsShellfishValue == null || formsShellfishValue == undefined) {
      return true;
    }

    const selectedShellfishInclusionElement =
      this.shellfishInclusionRadioButtonTargets.find((element) => {
        return element.checked;
      });

    const selectedShellfishInclusion = selectedShellfishInclusionElement
      ? selectedShellfishInclusionElement.value
      : "false";

    return selectedShellfishInclusion == formsShellfishValue;
  }

  _trackOnCustomerDataPlatform(profileData) {
    if (this.hasCdpTrackerIdentityValue && this.hasCdpTrackerTokenValue) {
      try {
        mixpanel.init(this.cdpTrackerTokenValue);
        mixpanel.identify(this.cdpTrackerIdentityValue);
        mixpanel.people.set(profileData);
      } catch (e) {}
    }
  }

  _activeShareTypes(selectTargetGroups) {
    return selectTargetGroups.reduceRight((acc, selectTargetGroup) => {
      const shareType = selectTargetGroup.shareSelectTarget.dataset.shareType;
      if (selectTargetGroup.matchesMeatCombos && acc.indexOf(shareType) == -1) {
        acc.push(shareType);
      }
      return acc;
    }, []);
  }
}
