import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["shareSelect", "cutPrefsContainer"];

  connect() {
    const selectedShare = this.shareSelectTargets.filter((target) => {
      return target.classList.contains("hidden") == false;
    })[0];
    this.toggleCutGroupsConfigSection(selectedShare);
  }

  toggleCutGroupsConfigSection(eventOrTarget) {
    const target = eventOrTarget.target || eventOrTarget;
    for (const cutPrefsContainer of this.cutPrefsContainerTargets) {
      if (cutPrefsContainer.dataset.cutGroupPrefContainerId == target.value) {
        cutPrefsContainer.classList.remove("hidden");
        this._enableAllNestedInputs(cutPrefsContainer);
      } else {
        cutPrefsContainer.classList.add("hidden");
        this._disableAllNestedInputs(cutPrefsContainer);
      }
    }
  }

  _enableAllNestedInputs(target) {
    for (const input of target.getElementsByTagName("input")) {
      if (input.hasAttribute("required") == false) {
        input.removeAttribute("disabled");
      }
      input.classList.remove("hidden");
    }
    for (const input of target.getElementsByTagName("select")) {
      input.removeAttribute("disabled");
      input.classList.remove("hidden");
    }
  }

  _disableAllNestedInputs(target) {
    for (const input of target.getElementsByTagName("input")) {
      input.setAttribute("disabled", "disabled");
      input.classList.add("hidden");
    }
    for (const input of target.getElementsByTagName("select")) {
      input.setAttribute("disabled", "disabled");
      input.classList.add("hidden");
    }
  }
}
