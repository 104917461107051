import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["length", "uppercaseLetterNumber", "submit"];

  connect() {
    this.validatePassword();
  }

  input(event) {
    this.password = event.target.value;
  }

  set password(value) {
    this.data.set("password", value);
    this.validatePassword();
  }

  get password() {
    if (!this.data.has("password")) {
      this.password = "";
    }

    return this.data.get("password");
  }

  validatePassword() {
    let valid = true;
    // Validate presence of numbers and capital letters
    let regex = /(?=.*[A-Z])(?=.*\d)/g;
    if (this.password.match(regex)) {
      this.uppercaseLetterNumberTarget.classList.toggle("invalid", false);
    } else {
      this.uppercaseLetterNumberTarget.classList.toggle("invalid", true);
      valid = false;
    }

    // Validate length
    if (this.password.length >= 8) {
      this.lengthTarget.classList.toggle("invalid", false);
    } else {
      this.lengthTarget.classList.toggle("invalid", true);
      valid = false;
    }

    // Enable/Disable submission
    if (valid == true) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
