import { Controller } from "stimulus";
import { embedCheckout } from "@bigcommerce/checkout-sdk";

export default class extends Controller {
  static values = {
    embeddedCheckoutUrl: String,
    confirmationUrl: String,
  };

  connect() {
    this.load();
  }

  load() {
    embedCheckout({
      containerId: "checkout-container",
      url: this.embeddedCheckoutUrlValue,
      onComplete: () => this._routeToConfirmation(),
    });
  }

  _routeToConfirmation() {
    window.location = this.confirmationUrlValue;
  }
}
