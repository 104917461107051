import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["accordionContent"];

  toggleAccordionContent() {
    this.accordionContentTargets.forEach((accordionContentTarget) => {
      if (accordionContentTarget.classList.contains("opacity-0")) {
        accordionContentTarget.classList.replace("opacity-0", "opacity-100");
        accordionContentTarget.classList.replace("grid-rows-0", "grid-rows-1");
      } else {
        accordionContentTarget.classList.replace("opacity-100", "opacity-0");
        accordionContentTarget.classList.replace("grid-rows-1", "grid-rows-0");
      }
    });
  }
}
