import { Controller } from "stimulus";

export default class extends Controller {
  static outlets = ["form-validations"];
  static targets = ["meatType", "container", "button", "errorNotice"];

  connect() {
    if (this.hasContainerTarget) {
      this.toggleShellfishInclusionFromSelectedMeatTypeCombo();
    }
  }

  toggleShellfishInclusionFromSelectedMeatTypeCombo() {
    if (this._selectedMeatTypesWithShellfishInclusion().size > 0) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.containerTarget.classList.add("hidden");
      this.validateSelection();
    }
  }

  validateSelection() {
    if (this._selectedMeatTypesWithShellfishInclusion().size > 0) {
      const selectedShellfishOption = this.buttonTargets.find(
        (button) => button.checked,
      );

      if (selectedShellfishOption) {
        this.errorNoticeTarget.classList.add("hidden");
      } else {
        this.errorNoticeTarget.classList.remove("hidden");
      }
    } else {
      this.errorNoticeTarget.classList.add("hidden");
    }

    this.formValidationsOutlet.checkValidations();
  }

  _selectedMeatTypesWithShellfishInclusion() {
    return new Set(
      this.meatTypeTargets.filter(
        (target) =>
          target.checked && target.dataset.includesShellfishInclusion == "true",
      ),
    );
  }
}
