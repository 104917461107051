import { Controller } from "stimulus";
import { debounce } from "../../utils/debounce";

export default class extends Controller {
  static targets = ["meatType", "cutGroup"];

  initialize() {
    this._refreshCutGroupSelections = debounce(
      this._refreshCutGroupSelections,
      10,
    ).bind(this);
  }

  cutGroupTargetConnected() {
    this._refreshCutGroupSelections();
  }

  toggleRelatedCutGroups(e) {
    // NOTE: isTrusted detects if trigger was user action
    if (e.isTrusted) {
      const meatType = e.target.dataset.meatType;
      if (meatType !== undefined) {
        this._toggleOnMeatType(meatType, e.target.checked);
      }
    }
  }

  reEnableRelatedMeatTypes(e) {
    // NOTE: isTrusted detects if trigger was user action
    if (e.isTrusted) {
      if (e.target.checked) {
        for (const meatType of e.target.dataset.meatTypes.split(",")) {
          const meatTypeTarget = this.meatTypeTargets.filter(
            (target) => target.dataset.meatType == meatType,
          )[0];
          if (meatTypeTarget != undefined && meatTypeTarget.checked == false) {
            meatTypeTarget.checked = true;
          }
          meatTypeTarget.dispatchEvent(new Event("change"));
        }
      }
    }
  }

  _toggleOnMeatType(meatType, meatTypeIsChecked) {
    for (const cutGroupTarget of this.cutGroupTargets) {
      if (
        cutGroupTarget.dataset.meatTypes.split(",").indexOf(meatType) !== -1
      ) {
        if (
          meatTypeIsChecked &&
          cutGroupTarget.dataset.autoReselect == "true"
        ) {
          cutGroupTarget.checked = true;
        } else if (cutGroupTarget.hasAttribute("required") == false) {
          cutGroupTarget.checked = false;
        }
        cutGroupTarget.dispatchEvent(new Event("change"));
      }
    }
  }

  _refreshCutGroupSelections() {
    for (const meatTypeTarget of this.meatTypeTargets) {
      // NOTE: only run if the meat type is not selected, because if it is active,
      //       then we don't know if the member has some of the related cut groups
      //       excluded (and running _toggleOnMeatType might reactivate everything
      //       in the cut group). If it's inactive, then we know we definitely want
      //       to opt out of every cut group
      if (meatTypeTarget.checked == false) {
        this._toggleOnMeatType(
          meatTypeTarget.dataset.meatType,
          meatTypeTarget.checked,
        );
      }
    }
  }
}
