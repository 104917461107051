import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["displayOrderInput"];
  // This controller will adjust the DOV (display order value of) products based on
  // user input. newDOV is the new display order value entered on the page, originalDOV is
  // is the original display order value. Any display order value in the originalDOV-newDOV
  // range will be changed by the controller (+1 for a decrease and -1 for an increase)
  // to avoid duplicate display order values. On page load the controller will set
  // the display order values from 1 to n to ensure correct ordering and no duplicates.

  connect() {
    this._setDisplayOrder();
  }

  // sets the values based on position from 1 to n amount of products
  _setDisplayOrder() {
    for (const [
      i,
      displayOrderInput,
    ] of this.displayOrderInputTargets.entries()) {
      displayOrderInput.value = i + 1;
      displayOrderInput.dataset.originalValue = displayOrderInput.value;
    }
  }

  // when a numerical value is entered on the page this function will wait 250ms
  // then change the DOVs of all products within the originalDOV - newDOV range. After
  // this it ensures that the event target DOV is correct.
  updateDisplayOrder(event) {
    let newDOV = parseInt(event.target.value);
    let originalDOV = parseInt(event.target.dataset.originalValue);
    if (typeof newDOV == "number" && typeof originalDOV == "number") {
      if (originalDOV < newDOV) {
        this._adjustDisplayOrderValues(
          this.displayOrderInputTargets,
          originalDOV,
          newDOV,
          -1,
        );
      } else if (originalDOV > newDOV) {
        this._adjustDisplayOrderValues(
          this.displayOrderInputTargets,
          originalDOV,
          newDOV,
          1,
        );
      }
      this._setTargetDisplayOrderValues(event.target, newDOV);
    }
  }

  // applies an adjustment (+1 for a decrease and -1 for an increase) to all
  // products in the range originalDOV - newDOV and their originalDOVs.
  _adjustDisplayOrderValues(
    displayOrderInputTargets,
    originalDOV,
    newDOV,
    adjustment,
  ) {
    for (const displayOrderInput of displayOrderInputTargets) {
      let DOV = parseInt(displayOrderInput.value);
      if (this._inRange(DOV, originalDOV, newDOV)) {
        displayOrderInput.value = DOV + adjustment;
        displayOrderInput.dataset.originalValue = displayOrderInput.value;
      }
    }
  }

  // Ensures the event target DOV and originalDOV are correct after all adjustments.
  _setTargetDisplayOrderValues(target, newDOV) {
    target.value = newDOV;
    target.dataset.originalValue = target.value;
  }

  // checks if a given DOV is within the range of originalDOV and newDOV
  _inRange(DOV, originalDOV, newDOV) {
    return (DOV - originalDOV) * (DOV - newDOV) <= 0;
  }
}
