import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pollingDuration: Number, pollingLink: String };
  static timeoutId;

  connect() {
    this.timeoutId = setInterval(() => {
      fetch(this.pollingLinkValue, { redirect: "manual" })
        .then((response) => response.json())
        .then((data) => {
          if (data.path) {
            Turbo.visit(data.path);
          }
        });
    }, this.pollingDurationValue);
  }

  disconnect() {
    clearTimeout(this.timeoutId);
  }
}
