import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (window.location.pathname == "/contact-us-general") {
      zE(function () {
        zE.activate();
      });
    }
    window.zESettings = {
      webWidget: {
        color: {
          theme: "#183028",
          launcher: "#AEBBBD",
        },
        launcher: {
          mobile: {
            labelVisible: true,
          },
          label: {
            "*": "Get in touch",
          },
          badge: {
            label: {
              "*": "Get in touch",
            },
          },
        },
      },
    };
  }
}
