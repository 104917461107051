import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "shareCard",
    "meatType",
    "meatTypeValidationErrorNotice",
    "validationErrorNotice",
    "shareCardsContainer",
  ];

  static outlets = ["form-validations"];

  toggleActiveShareCards(activeShareType) {
    for (const shareCardTarget of this.shareCardTargets) {
      if (shareCardTarget.dataset.shareType == activeShareType) {
        shareCardTarget.parentElement.classList.add("selected");
        shareCardTarget.parentElement.classList.add("selected-v2");
        this._toggleApplicableMeatTypes(
          shareCardTarget.dataset.eligibleMeatTypes,
        );
      } else {
        shareCardTarget.parentElement.classList.remove("selected");
        shareCardTarget.parentElement.classList.remove("selected-v2");
      }
    }
    this._validateOneShareTypeIsSelected();
  }

  // NOTE: run by the ShareSelectionController whenever meat combos change
  toggleApplicableShares(shareTypesValidForMeatCombo) {
    for (const shareCard of this.shareCardTargets) {
      if (
        shareTypesValidForMeatCombo.indexOf(shareCard.dataset.shareType) >= 0
      ) {
        shareCard.classList.remove("disabled-option");
      } else {
        shareCard.classList.add("disabled-option");
      }
    }
    this._validateOneShareTypeIsSelected();
    this._showOrHideMeatComboValidationError();
  }

  _toggleApplicableMeatTypes(validMeatTypeNames) {
    for (const meatType of this.meatTypeTargets) {
      if (validMeatTypeNames.includes(meatType.dataset.meatType)) {
        meatType.parentElement.classList.remove("disabled-option");
      } else {
        meatType.parentElement.classList.add("disabled-option");
      }
    }
    this._showOrHideMeatComboValidationError();
  }

  _validateOneShareTypeIsSelected() {
    let selectedShareCards = this.shareCardTargets.filter((shareCard) => {
      return shareCard.parentElement.classList.contains("selected");
    });

    if (selectedShareCards.length < 1) {
      this.validationErrorNoticeTarget.classList.remove("hidden");
    } else {
      this.validationErrorNoticeTarget.classList.add("hidden");
    }
    this.formValidationsOutlet.checkValidations();
  }

  _showOrHideMeatComboValidationError() {
    let disabledShareCards = this.shareCardTargets.filter((shareCard) => {
      return shareCard.classList.contains("disabled-option");
    });

    let selectedMeatTypes = this.meatTypeTargets.filter((meatType) => {
      return meatType.checked;
    });

    if (
      selectedMeatTypes.length > 0 &&
      disabledShareCards.length == this.shareCardTargets.length
    ) {
      this._meatComboValidationErrorMessage(
        this.meatTypeValidationErrorNoticeTarget,
        selectedMeatTypes,
      );
      this.meatTypeValidationErrorNoticeTarget.classList.remove("hidden");
    } else {
      this.meatTypeValidationErrorNoticeTarget.classList.add("hidden");
    }
    this.formValidationsOutlet.checkValidations();
  }

  _meatComboValidationErrorMessage(errorTarget, selectedMeats) {
    let selectedMeatsString = this._presentMeatCombo(selectedMeats);
    let errorMessage =
      "Unfortunately, we don't currently offer any shares with only " +
      selectedMeatsString +
      ". Please adjust your selection.";

    errorTarget.innerHTML = errorMessage;
  }

  _presentMeatCombo(selectedMeats) {
    let meats = selectedMeats.map((meat) => meat.dataset.meatType);
    let l = meats.length;
    if (l < 2) return meats[0];
    if (l < 3) return meats.join(` & `);
    let lastMeat = ` & ${meats.pop()}`;
    return meats.join(", ") + lastMeat;
  }
}
