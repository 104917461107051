import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "newDayToggle",
    "newDaySection",
    "existingDaySection",
    "existingDaySelect",
    "newZipGroupToggle",
    "existingZipGroupSection",
    "existingZipGroupSelect",
    "newZipGroupSection",
  ];

  connect() {
    this._initEnabledModes();
    this._hideTogglesForDisabledModes();
    this._updateForm();
  }

  toggleNewOrExistingDay() {
    if (this.newDayToggleTarget.checked) {
      if (
        this._isModeEnabled("newDayExistingGroup") &&
        this.newZipGroupToggleTarget.checked == false
      ) {
        this._changeModeTo("newDayExistingGroup");
      } else {
        this._changeModeTo("newDayNewGroup");
      }
    } else {
      this._changeModeTo("existingDay");
    }
  }

  toggleNewOrExistingZipGroup() {
    if (this.newZipGroupToggleTarget.checked) {
      this._changeModeTo("newDayNewGroup");
    } else {
      this._changeModeTo("newDayExistingGroup");
    }
  }

  _initEnabledModes() {
    this.enabledModes = ["newDayNewGroup"];
    if (this.existingZipGroupSelectTarget.options.length > 0) {
      this.enabledModes.unshift("newDayExistingGroup");
    }
    if (this.existingDaySelectTarget.options.length > 0) {
      this.enabledModes.unshift("existingDay");
    }
    this.currentMode = this.enabledModes[0];
  }

  _hideTogglesForDisabledModes() {
    if (this._isModeDisabled("existingDay")) {
      this.newDayToggleTarget.parentElement.setAttribute("hidden", "hidden");
    }
    if (this._isModeDisabled("newDayExistingGroup")) {
      this.newZipGroupToggleTarget.parentElement.setAttribute(
        "hidden",
        "hidden",
      );
    }
  }

  _updateForm() {
    if (this.currentMode == "newDayNewGroup") {
      this._hideAndDisable(this.existingDaySectionTarget);
      this._showAndEnable(this.newDaySectionTarget);

      this._hideAndDisable(this.existingZipGroupSectionTarget);
      this._showAndEnable(this.newZipGroupSectionTarget);
    } else if (this.currentMode == "newDayExistingGroup") {
      this._hideAndDisable(this.existingDaySectionTarget);
      this._showAndEnable(this.newDaySectionTarget);

      this._hideAndDisable(this.newZipGroupSectionTarget);
      this._showAndEnable(this.existingZipGroupSectionTarget);
    } else {
      this._hideAndDisable(this.newDaySectionTarget);
      this._showAndEnable(this.existingDaySectionTarget);
    }
  }

  _changeModeTo(mode) {
    this.currentMode = this._isModeEnabled(mode) ? mode : this.enabledModes[0];
    this._updateForm();
  }

  _isModeEnabled(mode) {
    return this.enabledModes.indexOf(mode) > -1;
  }

  _isModeDisabled(mode) {
    return this.enabledModes.indexOf(mode) == -1;
  }

  _showAndEnable(sectionTarget) {
    this._toggleDisabledAll(sectionTarget, false);
    sectionTarget.removeAttribute("hidden");
  }

  _hideAndDisable(sectionTarget) {
    this._toggleDisabledAll(sectionTarget, true);
    sectionTarget.setAttribute("hidden", "hidden");
  }

  _toggleDisabledAll(parent, disable) {
    for (const inputType of ["input", "select"]) {
      for (const el of parent.querySelectorAll(inputType)) {
        this._toggleDisabled(el, disable);
      }
    }
  }

  _toggleDisabled(el, disable) {
    if (disable) {
      el.setAttribute("disabled", "disabled");
    } else {
      el.removeAttribute("disabled");
    }
  }
}
