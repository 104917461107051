import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "selectionCheckbox",
    "selectionSummaryText",
    "quantitySelectField",
    "noItemsPrompt",
  ];

  connect() {
    this._setSelectionSummaryTextQuantities();
  }

  addItem(event) {
    const qtySelectField = event.target;
    const productSku = qtySelectField.dataset.productSku;
    const quantity = qtySelectField.options[qtySelectField.selectedIndex].value;

    if (quantity > 0) {
      this._showSelection(productSku, quantity);
    } else {
      this._hideSelection(productSku);
    }
    this._showOrHideNoItemsPrompts();
  }

  removeItem(event) {
    let productSku = event.target.dataset.productSku;
    for (const quantitySelectField of this.quantitySelectFieldTargets) {
      if (quantitySelectField.dataset.productSku == productSku) {
        quantitySelectField.options[0].selected = true;
        quantitySelectField.dispatchEvent(new Event("change"));
        this._hideSelection(productSku);
      }
    }
    this._showOrHideNoItemsPrompts();
  }

  _showSelection(productSku, quantity) {
    for (const selection of this.selectionCheckboxTargets) {
      if (selection.dataset.productSku == productSku) {
        this._updateQuantityShown(productSku, quantity);
        selection.checked = true;
        selection.dataset.quantity = quantity;
        selection.dispatchEvent(new Event("change"));
      }
    }
  }

  _hideSelection(productSku) {
    for (const selection of this.selectionCheckboxTargets) {
      if (selection.dataset.productSku == productSku) {
        selection.checked = false;
        selection.dataset.quantity = 0;
        selection.dispatchEvent(new Event("change"));
      }
    }
  }

  _showOrHideNoItemsPrompts() {
    const visibleSelections = this.selectionCheckboxTargets.filter(
      (checkbox) => checkbox.checked,
    );

    for (const promptTarget of this.noItemsPromptTargets) {
      if (this._anyVisibleSelections(promptTarget, visibleSelections)) {
        promptTarget.classList.add("hidden");
      } else {
        promptTarget.classList.remove("hidden");
      }
    }
  }

  _anyVisibleSelections(promptTarget, visibleSelections) {
    for (const visibleSelection of visibleSelections) {
      if (
        visibleSelection.closest(".items-summary-group") ===
        promptTarget.closest(".items-summary-group")
      ) {
        return true;
      }
    }
    return false;
  }

  _updateQuantityShown(productSku, quantity) {
    for (const target of this.selectionSummaryTextTargets) {
      if (target.dataset.productSku == productSku) {
        this._updateQuantityText(target, quantity);
      }
    }
  }

  _setSelectionSummaryTextQuantities() {
    for (const selectionSummaryText of this.selectionSummaryTextTargets) {
      let productSku = selectionSummaryText.dataset.productSku;
      let matchingQuantitySelectField = this.quantitySelectFieldTargets.find(
        (quantitySelectField) =>
          quantitySelectField.dataset.productSku == productSku,
      );
      let quantity =
        matchingQuantitySelectField.options[
          matchingQuantitySelectField.selectedIndex
        ].value;
      this._updateQuantityText(selectionSummaryText, quantity);
    }
  }

  _updateQuantityText(target, quantity) {
    let text = target.innerHTML;
    let updatedText = text
      .replace(/\([\d{0,2}]*\)/g, "()")
      .replace("()", `(${quantity})`);
    target.innerHTML = updatedText;
  }
}
