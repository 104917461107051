import { Controller } from "stimulus";
import { updateUrl } from "../utils/update_url";

export default class extends Controller {
  updateOpsChannel(event) {
    let channel = event.target.value;
    let params = { channel: channel };
    updateUrl(params);
  }
}
