import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["section"];

  toggleSection(event) {
    let selection = event.target.options[event.target.selectedIndex].value;

    this.sectionTargets.forEach((sectionTarget) => {
      let optionIds = JSON.parse(sectionTarget.dataset.optionIds);

      if (optionIds.includes(selection)) {
        sectionTarget.classList.remove("hidden");
      } else {
        sectionTarget.classList.add("hidden");
      }
    });
  }
}
