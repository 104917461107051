import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["heading", "categoryTab"];

  selectCategory(event) {
    const filterCategory =
      event.target.parentElement.dataset.productCategoryName;
    const categoryId = event.target.parentElement.dataset.productCategoryId;
    this._updateHeading(filterCategory);
    this._updateCategoryTabs(filterCategory);
    this._updateUrl(categoryId);
  }

  _updateHeading(filterCategory) {
    this.headingTargets.forEach((heading) => {
      var categoryName = filterCategory.replace(/_/g, " ");
      if (categoryName == "Featured") {
        heading.innerHTML =
          categoryName +
          "<p class='text-sm'>Looking to prepay your account? <a href=/prepay>Click here!</a></p>";
      } else {
        heading.innerHTML = categoryName;
      }
    });
  }

  _updateCategoryTabs(filterCategory) {
    for (const categoryTab of this.categoryTabTargets) {
      if (categoryTab.dataset.productCategoryName == filterCategory) {
        this._selected(categoryTab);
      } else {
        this._unselected(categoryTab);
      }
    }
  }

  _updateUrl(categoryId) {
    let url = new URL(location.href);
    url.search = new URLSearchParams({
      product_category_id: categoryId,
    }).toString();
    history.pushState({}, "", url.href);
  }

  _selected(categoryTab) {
    categoryTab.classList.add("category-tab-selected");
    categoryTab.classList.remove("category-tab-unselected");
  }

  _unselected(categoryTab) {
    categoryTab.classList.remove("category-tab-selected");
    categoryTab.classList.add("category-tab-unselected");
  }
}
