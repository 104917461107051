import { Controller } from "stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static values = {
    token: String,
    identity: String,
  };

  static targets = ["trackedOnLoad"];

  initialize() {
    mixpanel.init(this.tokenValue);
    mixpanel.identify(this.identityValue);
  }

  connect() {
    const options = {
      path: window.location.pathname,
    };
    this._campaignParams();
    mixpanel.track(`Visit ${document.title}`, options);
  }

  trackedOnLoadTargetConnected(target) {
    let options = {};
    if (target.dataset.cdpTrackerDataValue !== undefined) {
      options = JSON.parse(target.dataset.cdpTrackerDataValue);
    }
    options["path"] = window.location.pathname;
    let eventName = target.dataset.cdpTrackerEventNameValue;
    mixpanel.track(eventName, options);
  }

  track(event) {
    let options = {};
    if (event.target.dataset.cdpTrackerDataValue !== undefined) {
      options = JSON.parse(event.target.dataset.cdpTrackerDataValue);
    }
    options["path"] = window.location.pathname;
    let eventName = event.target.dataset.cdpTrackerEventNameValue;
    mixpanel.track(eventName, options);
  }

  _getQueryParam(url, param) {
    // Expects a raw URL
    param = param.replace(/[[]/, "[").replace(/[]]/, "]");
    var regexS = "[?&]" + param + "=([^&#]*)",
      regex = new RegExp(regexS),
      results = regex.exec(url);
    if (
      results === null ||
      (results && typeof results[1] !== "string" && results[1].length)
    ) {
      return "";
    } else {
      return decodeURIComponent(results[1]).replace(/\W/gi, " ");
    }
  }

  _campaignParams() {
    var campaign_keywords = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
      ],
      kw = "",
      params = {},
      first_params = {};
    var index;
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = this._getQueryParam(document.URL, campaign_keywords[index]);
      if (kw.length) {
        params[campaign_keywords[index] + " [last touch]"] = kw;
      }
    }
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = this._getQueryParam(document.URL, campaign_keywords[index]);
      if (kw.length) {
        first_params[campaign_keywords[index] + " [first touch]"] = kw;
      }
    }
    mixpanel.people.set(params);
    mixpanel.people.set_once(first_params);
    mixpanel.register(params);
  }
}
