import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "emailInput",
    "invalidEmail",
    "addEmail",
    "emailList",
    "emailListTemplate",
    "addedEmail",
    "emailsToSubmit",
    "submitEmails",
  ];

  onEmailChange(event) {
    let email = event.target.value;
    var re = /\S+@\S+\.\S+/;
    if (re.test(email) || email.length == 0) {
      this.invalidEmailTarget.classList.add("hidden");
      this.addEmailTarget.disabled = false;
    } else {
      this.invalidEmailTarget.classList.remove("hidden");
      this.addEmailTarget.disabled = true;
    }
  }

  removeItem(event) {
    let email = event.target.dataset.email;
    this._deleteSelection(email);
    if (this.addedEmailTargets.length == 0) {
      this.submitEmailsTarget.disabled = true;
    }
    let currentEmails = this.emailsToSubmitTarget.value;
    this.emailsToSubmitTarget.value = currentEmails.replace(`${email},`, "");
  }

  addItem(event) {
    let emailInputTarget = this.emailInputTarget;
    let email = emailInputTarget.value;
    this._createSelection(emailInputTarget.value);
    this.emailsToSubmitTarget.value += `${email},`;

    emailInputTarget.value = "";
    this.addEmailTarget.disabled = true;
    this.submitEmailsTarget.disabled = false;
  }

  _deleteSelection(email) {
    for (const addedEmail of this.addedEmailTargets) {
      if (addedEmail.dataset.email == email) {
        let clonedAddedEmail = addedEmail.firstElementChild.cloneNode(false);
        addedEmail.parentNode.replaceChild(clonedAddedEmail, addedEmail);
      }
    }
  }

  _createSelection(email) {
    let emailListTarget = this.emailListTarget;
    let template = this.emailListTemplateTarget;

    let clone = template.content.firstElementChild.cloneNode(true);
    let emailHolder = clone.querySelector("div p");
    emailHolder.textContent = email;
    let removeEmailIcon = clone.querySelector("div i");
    removeEmailIcon.setAttribute("data-email", email);
    clone.setAttribute("data-email", email);

    emailListTarget.appendChild(clone);
  }
}
