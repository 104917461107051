import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    copyable: String,
  };

  copy(event) {
    navigator.clipboard.writeText(this.copyableValue);
  }
}
