import { Controller } from "stimulus";
export default class extends Controller {
  static values = { minPoints: Number, maxPoints: Number };
  static targets = ["checkBox", "notice"];
  static outlets = ["form-validations"];

  connect() {
    if (this.hasNoticeTarget) {
      this.revalidate();
    }
  }

  revalidate() {
    let selectedPoints = 0;
    for (const checkbox of this.checkBoxTargets) {
      if (checkbox.checked) {
        const pointsValue = checkbox.dataset.pointsValue || 1;
        selectedPoints += parseInt(pointsValue);
      }
    }

    const minValue = this.minPointsValue || 1;
    const maxValue = this.maxPointsValue || 1000;
    if (selectedPoints < minValue || selectedPoints > maxValue) {
      for (const noticeTarget of this.noticeTargets) {
        noticeTarget.classList.remove("hidden");
      }
    } else {
      for (const noticeTarget of this.noticeTargets) {
        noticeTarget.classList.add("hidden");
      }
    }
    this.formValidationsOutlet.checkValidations();
  }
}
