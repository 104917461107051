import { Controller } from "stimulus";
import { getBrowser } from "../utils/get_browser";

export default class extends Controller {
  static targets = ["mainBody"];

  connect() {
    const browser = getBrowser();
    if (
      this._isSafariVersionWithPoorHeightFlexSupport(browser) ||
      this._isChromeVersionWithPoorHeightFlexSupport(browser)
    ) {
      this.mainBodyTarget.classList.remove("flex");
      this.mainBodyTarget.classList.remove("flex-col");
      this.mainBodyTarget.classList.remove("flex-1");
    }
  }

  _isSafariVersionWithPoorHeightFlexSupport(browser) {
    return browser.name == "Safari" && parseInt(browser.version) < 14;
  }

  _isChromeVersionWithPoorHeightFlexSupport(browser) {
    return browser.name == "Chrome" && parseInt(browser.version) <= 72;
  }
}
