import { Controller } from "stimulus";

const classMap = {
  orangeToggleClasses: [
    // Classes when not expanded
    "bg-white",
    "text-walden-orange",
    "hover:bg-walden-orange",
    "hover:text-white",
    // Classes when expanded
    "bg-walden-orange",
    "text-white",
    "hover:bg-walden-dark-orange",
  ],
};

export default class extends Controller {
  static values = { classList: String };
  static targets = ["contentHolder", "contentToggler"];

  showContent(event) {
    event.preventDefault();
    for (const contentHolder of this.contentHolderTargets) {
      contentHolder.classList.remove("hidden");
    }
  }

  hideContent(event) {
    event && event.preventDefault();
    for (const contentHolder of this.contentHolderTargets) {
      contentHolder.classList.add("hidden");
    }
  }

  toggleContent(event) {
    event.currentTarget && this._toggleActiveOrInactive(event.currentTarget);
    for (const contentHolder of this.contentHolderTargets) {
      this._toggleHidden(contentHolder);
    }
  }

  _toggleActiveOrInactive(contentToggler) {
    if (this.hasClassListValue) {
      for (const htmlclass of classMap[this.classListValue]) {
        contentToggler.classList.toggle(htmlclass);
      }
    }
    const icon = contentToggler.querySelector("i");
    if (icon != undefined) {
      icon.classList.toggle("fa-angle-double-down");
      icon.classList.toggle("fa-angle-double-up");
    }
  }

  _toggleHidden(contentHolder) {
    contentHolder.classList.toggle("hidden");
  }
}
