import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectField"];
  static values = { checkedOptions: Array, uncheckedOptions: Array };

  updateDropdownOptions(event) {
    const selectedValue = this.selectFieldTarget.value;
    this.selectFieldTarget.innerHTML = "";

    if (event.target.checked) {
      this.checkedOptionsValue.forEach((option) => {
        this.selectFieldTarget.options.add(new Option(option[0], option[1]));
      });
    } else {
      this.uncheckedOptionsValue.forEach((option) => {
        this.selectFieldTarget.options.add(new Option(option[0], option[1]));
      });
    }

    this.selectFieldTarget.value = selectedValue;
  }
}
