import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  toggleHidden() {
    this.modalTarget.classList.toggle("hidden");

    if (this.modalTarget.classList.contains("hidden")) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }
  }
}
