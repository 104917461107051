import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  change(e) {
    if (e.detail == "syncCheckboxes") {
      return;
    }
    for (const checkboxTarget of this.checkboxTargets) {
      if (checkboxTarget.id == e.target.id && checkboxTarget != e.target) {
        this._syncChecked(checkboxTarget, e.target);
      }
    }
  }

  checkboxTargetConnected(connectedCheckboxTarget) {
    for (const checkboxTarget of this.checkboxTargets) {
      if (
        checkboxTarget.id == connectedCheckboxTarget.id &&
        checkboxTarget != connectedCheckboxTarget
      ) {
        this._syncChecked(connectedCheckboxTarget, checkboxTarget);
        return;
      }
    }
  }

  _syncChecked(followerTarget, leaderTarget) {
    if (followerTarget.checked != leaderTarget.checked) {
      followerTarget.checked = leaderTarget.checked;
      followerTarget.dispatchEvent(
        new CustomEvent("change", { detail: "syncCheckboxes" }),
      );
    }
  }
}
