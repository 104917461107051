import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.zendesk = this._initClient();
    if (this.zendesk) {
      this.zendesk.on("app.registered", this._handleAppEvent);
      this.zendesk.on("app.activated", this._handleAppEvent);
      this._handleAppEvent();
    }
  }

  disconnect() {
    if (this.zendesk) {
      this.zendesk.off("app.registered", this._handleAppEvent);
      this.zendesk.off("app.activated", this._handleAppEvent);
    }
  }

  _initClient() {
    if (typeof ZAFClient !== "undefined" && "init" in ZAFClient) {
      return ZAFClient.init();
    }
  }

  async _handleAppEvent() {
    const context = await this.zendesk.context();
    let email;

    // If we're on a user profile page, extract the email from the user context.
    if ("userId" in context) {
      const { user } = await this.zendesk.get("user");
      email = user.email;
    } else if ("ticketId" in context) {
      // If we're on a ticket, extract the email from the ticket context.
      const { ticket } = await this.zendesk.get("ticket");
      if (ticket.requester && ticket.requester.email) {
        email = ticket.requester.email;
      }
    }

    // If we were able to extract an email from the context, load the corresponding
    // member profile if it exists.
    if (email) {
      Turbo.visit(
        `/members?q=${email}&search_criteria=email_cont&commit=submit`,
      );
    }
  }
}
