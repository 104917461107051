import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mainToggle", "checkbox"];

  connect() {
    this.onChange();
  }

  toggleAll(e) {
    for (const target of this.checkboxTargets) {
      target.checked = this.mainToggleTarget.checked;
    }
  }

  onChange(e) {
    const checked = this.checkboxTargets.filter((checkbox) => checkbox.checked);
    this.mainToggleTarget.checked =
      checked.length == this.checkboxTargets.length;
  }
}
