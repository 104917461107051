import { Controller } from "stimulus";

const sum = (acc, a) => {
  return acc + a;
};

export default class extends Controller {
  static values = { limit: Number };
  static targets = ["quantitySelect"];

  connect() {
    this.updateSelectFieldsToLimit();
  }

  updateSelectFieldsToLimit() {
    const selectedQuantity = this.quantitySelectTargets
      .map((target) => {
        if (target.selectedIndex != undefined) {
          return parseInt(target.options[target.selectedIndex].value) || 0;
        } else {
          return 0;
        }
      })
      .reduce(sum, 0);

    const remaining = this.limitValue - selectedQuantity;

    this.quantitySelectTargets.forEach((target) => {
      const selectedHere =
        parseInt(target.options[target.selectedIndex].value) || 0;
      const remainingHere = selectedHere + remaining;

      this._disableOptionsExceedingLimit(target, remainingHere);
      this._enableOptionsUpToLimit(target, remainingHere);
    });
  }

  _disableOptionsExceedingLimit(target, remainingHere) {
    for (var i = target.options.length - 1; i > 0; i--) {
      if (
        i > target.selectedIndex &&
        parseInt(target.options[i].value) > remainingHere
      ) {
        target.options[i].setAttribute("disabled", "disabled");
      }
    }
  }

  _enableOptionsUpToLimit(target, remainingHere) {
    var stockLimit = parseInt(target.dataset.quantityLimit);
    if (stockLimit == NaN) {
      stockLimit = 999;
    }
    const localLimit = Math.min(stockLimit, remainingHere);
    for (var i = target.options.length - 1; i > 0; i--) {
      if (
        i > target.selectedIndex &&
        parseInt(target.options[i].value) <= localLimit
      ) {
        target.options[i].removeAttribute("disabled");
      }
    }
  }
}
