import { Controller } from "stimulus";

export default class extends Controller {
  static get values() {
    return {
      message: String,
    };
  }

  confirm(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (confirm(this.messageValue)) {
      event.target.closest("form").submit();
    }
  }
}
