import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field"];

  toggleField(event) {
    let selection = parseInt(
      event.target.options[event.target.selectedIndex].value,
    );

    this.fieldTargets.forEach((fieldTarget) => {
      let optionIds = JSON.parse(fieldTarget.dataset.optionIds);

      if (optionIds.includes(selection)) {
        fieldTarget.classList.remove("hidden");
        fieldTarget.classList.add("input-registration-new");
      } else {
        fieldTarget.classList.add("hidden");
        fieldTarget.classList.remove("input-registration-new");
      }
    });
  }
}
