import { Controller } from "stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static targets = [
    "indicatedHouseholdSizeOption",
    "indicatedServingsPerWeekOption",
    "formIndicatedHouseholdSize",
    "formIndicatedServingsPerWeek",
    "sizeOption",
    "recommendationText",
  ];

  recalculateRecommendation() {
    this._updateRecommendationTextAndOption();
  }

  updateIndicatedHouseholdSize(event) {
    this._updateForm(
      event,
      this.formIndicatedHouseholdSizeTarget,
      this.indicatedHouseholdSizeOptionTargets,
    );
    this._updateRecommendationTextAndOption();
  }

  updateIndicatedServingsPerWeek(event) {
    this._updateForm(
      event,
      this.formIndicatedServingsPerWeekTarget,
      this.indicatedServingsPerWeekOptionTargets,
    );
    this._updateRecommendationTextAndOption();
  }

  _updateForm(event, formTarget, optionTargets) {
    formTarget.value = event.currentTarget.dataset.value;
    this._switchSelectedOptionTo(event.currentTarget, optionTargets);
  }

  _switchSelectedOptionTo(currentTarget, optionTargets) {
    for (const option of optionTargets) {
      option.classList.remove("selected-v2");
    }
    currentTarget.classList.add("selected-v2");
  }

  _updateRecommendationTextAndOption() {
    let recommendedSizeOption = this._determineRecommendedSizeOption();
    this.recommendationTextTarget.innerHTML =
      recommendedSizeOption.dataset.size;
    recommendedSizeOption.checked = true;
  }

  _determineRecommendedSizeOption() {
    let enabledSizeOptions = this.sizeOptionTargets.filter(
      (el) => !el.disabled,
    );
    let sortedSizeOptionsAscending =
      this._sortSizeOptionsAscending(enabledSizeOptions);
    let poundsPm = this._poundsPerMonth();

    // cycle through each size option smallest to largest
    for (const sizeOption of sortedSizeOptionsAscending) {
      let maxWeight = parseFloat(sizeOption.dataset.maxWeight);

      if (poundsPm <= maxWeight) {
        return sizeOption;
      }
    }
    // return the largest option if poundsPm larger than heaviest max weight
    return sortedSizeOptionsAscending[sortedSizeOptionsAscending.length - 1];
  }

  _sortSizeOptionsAscending(enabledSizeOptions) {
    let sortedSizeOptions = [];
    let maxWeightsUnsorted = enabledSizeOptions.map((sizeOption) =>
      parseFloat(sizeOption.dataset.maxWeight, 10),
    );
    let maxWeightsAscending =
      this._sortNumericalArrayAscending(maxWeightsUnsorted);
    for (const maxWeight of maxWeightsAscending) {
      let unsortedIndex = maxWeightsUnsorted.indexOf(maxWeight);
      sortedSizeOptions.push(enabledSizeOptions[unsortedIndex]);
    }
    return sortedSizeOptions;
  }

  _sortNumericalArrayAscending(numericalArray) {
    return numericalArray.sort(function (a, b) {
      return a - b;
    });
  }

  _poundsPerMonth() {
    let num_people = this.formIndicatedHouseholdSizeTarget.value;
    let num_servings_pw =
      this.formIndicatedServingsPerWeekTarget.value * num_people;
    let num_servings_pm = num_servings_pw * 4;
    let pounds_pm = (num_servings_pm * 1) / 2;
    return pounds_pm;
  }
}
