import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field"];

  toggleField(event) {
    let selection = parseInt(
      event.target.options[event.target.selectedIndex].value,
    );

    this.fieldTargets.forEach((fieldTarget) => {
      let optionIds = JSON.parse(fieldTarget.dataset.optionIds);

      if (optionIds.includes(selection)) {
        fieldTarget.setAttribute("required", true);
      } else {
        fieldTarget.removeAttribute("required");
      }
    });
  }
}
