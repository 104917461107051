import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    count: Number,
    increment: Number,
    maxQty: Number,
    copySingular: String,
    copyPlural: String,
  };
  static targets = [
    "count",
    "formQuantity",
    "plus",
    "errorMessage",
    "container",
    "hideable",
  ];

  connect() {
    this._updateText();
    this._setMaxQuantityState();
  }

  increment() {
    if (this.countValue < this.maxQtyValue) {
      this.countValue = this.countValue + this.incrementValue;
      this.formQuantityTarget.value = this.countValue;
      this._updateText(this.countValue);
      this._toggleSelected();
      this._setMaxQuantityState();
    }
  }

  decrement() {
    if (this.countValue > 0) {
      this.countValue = this.countValue - this.incrementValue;
      this.formQuantityTarget.value = this.countValue;
      this._updateText(this.countValue);
      this._toggleSelected();
      this._setMaxQuantityState();
    }
  }

  addToShare() {
    this.increment();
    this._toggleVisibility();
  }

  _toggleSelected() {
    if (this.countValue == "0") {
      this.containerTarget.classList.remove("selected-add-on");
      this._toggleVisibility();
    } else {
      this.containerTarget.classList.add("selected-add-on");
    }
  }

  _updateText() {
    if (this.countValue > 1) {
      this.countTarget.textContent =
        this.countValue + " " + this.copyPluralValue;
    } else {
      this.countTarget.textContent =
        this.countValue + " " + this.copySingularValue;
    }
  }

  _toggleVisibility() {
    this.hideableTargets.forEach((hideableTarget) => {
      hideableTarget.classList.toggle("hidden");
    });
  }

  _setMaxQuantityState() {
    if (this.countValue == this.maxQtyValue) {
      this.plusTarget.classList.add("disabled");
      this.errorMessageTarget.classList.remove("hidden");
    } else {
      this.plusTarget.classList.remove("disabled");
      this.errorMessageTarget.classList.add("hidden");
    }
  }
}
