import { Controller } from "stimulus";
import Appsignal from "@appsignal/javascript";
import { installErrorHandler } from "@appsignal/stimulus";

export default class extends Controller {
  static values = { key: String };

  connect() {
    const appsignal = new Appsignal({
      key: this.keyValue,
    });

    installErrorHandler(appsignal, this.application);
  }
}
