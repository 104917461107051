function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
document.addEventListener("DOMContentLoaded", function () {
  const $logout_link_el = document.getElementById("bc_logout_link");
  if ($logout_link_el != null) {
    const attrs = JSON.parse($logout_link_el.dataset.attrs);
    const opts = {
      method: "GET",
      mode: "no-cors", // This allows us to run the logout cross-origin, getting an opaque response.
      headers: {},
    };
    // This can return a 503 Internal Server Error when the site is down for maintenance
    fetch(attrs.link, opts).then((res) => {
      // NOTE: we can't do a standard try // catch error structure with fetch, because the default
      //    browser behaviour is structured differently for non-successful HTTP response codes
      //    see: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
      //
      //    Instead, we follow a structure that checks instead of the response was ok
      //    EXCEPT if the page is loaded in the Zendesk App iframe
      if (!res.ok && res.status >= 400 && inIframe() == false) {
        // If we ran into an error, send the user directly to the logout page
        window.location.href = attrs.link;
      }
    });
  }
});
