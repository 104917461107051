import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  toggle() {
    let check = !this.checkboxTargets[0].checked;

    this.checkboxTargets.forEach((checkboxTarget) => {
      checkboxTarget.checked = check;
    });
  }
}
