import { Controller } from "stimulus";

export default class extends Controller {
  onDeliveryDateChange(event) {
    let deliveryDate = event.target.value;
    let showAll = document.getElementById(`show_all`).checked;
    let params = { delivery_date: deliveryDate, show_all: showAll };
    updateUrl(params);
  }

  onRouteChange(event) {
    let routeID = event.target.value;
    let showAll = document.getElementById(`show_all`).checked;
    let deliveryDate = document.getElementById(`delivery_date`).value;
    let params = {
      delivery_date: deliveryDate,
      route_id: routeID,
      show_all: showAll,
    };
    updateUrl(params);
  }

  onShowAllChange(event) {
    let showAll = event.target.checked;
    let routeID = document.getElementById(`route_id`).value;
    let deliveryDate = document.getElementById(`delivery_date`).value;
    let params = {
      delivery_date: deliveryDate,
      route_id: routeID,
      show_all: showAll,
    };
    updateUrl(params);
  }
}

function updateUrl(params) {
  let url = new URL(location.href);
  url.search = new URLSearchParams(params).toString();
  location.href = encodeURI(url);
}
