// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("logout");
require.context("../images/", true, /\.(gif|jpg|png|svg)$/i);

import "@hotwired/turbo-rails";
import "controllers";

import "tailwindcss/components";
import "stylesheets/app-components.scss";
import "stylesheets/app-walden.scss";
