import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "zipGroupDeliveryDayInput", "submitInput"];

  selectZipGroupDeliveryDays() {
    const selected = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked,
    );
    const selectedIds = selected.map((checkbox) => checkbox.value);
    let disableSubmit = true;
    for (const target of this.zipGroupDeliveryDayInputTargets) {
      target.value = selectedIds;
      if (target.value) {
        disableSubmit = false;
      }
    }
    for (const target of this.submitInputTargets) {
      target.disabled = disableSubmit;
    }
  }

  toggleAllCheckboxes() {
    const checkOrUncheck =
      this.checkboxTargets.filter((checkbox) => !checkbox.checked).length > 0;
    for (const target of this.checkboxTargets) {
      target.checked = checkOrUncheck;
    }
    this.selectZipGroupDeliveryDays();
  }
}
