import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pageTitle: String };

  print() {
    document.body.innerHTML = this.element.innerHTML;

    if (this.pageTitleValue) {
      document.title = this.pageTitleValue;
    }

    window.print();

    location.reload();
  }
}
