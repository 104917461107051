import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "firstName",
    "lastName",
    "company",
    "phone",
    "address1",
    "address2",
    "city",
    "state",
    "zipCode",
    "commercial",
    "notes",
  ];

  static values = {
    addressAttrs: String,
  };

  updateAddressFields(e) {
    const addresses = JSON.parse(this.addressAttrsValue);
    let selectedAddress = addresses.filter(
      (address) => address["id"] == e.target.value,
    )[0];
    this._autofillAddressFields(selectedAddress);
    this.zipCodeTarget.dispatchEvent(new Event("input"));
  }

  _autofillAddressFields(selectedAddress) {
    this.firstNameTarget.value = selectedAddress["first_name"] || "";
    this.lastNameTarget.value = selectedAddress["last_name"] || "";
    this.companyTarget.value = selectedAddress["company"] || "";
    this.phoneTarget.value = selectedAddress["phone"] || "";
    this.address1Target.value = selectedAddress["address1"] || "";
    this.address2Target.value = selectedAddress["address2"] || "";
    this.cityTarget.value = selectedAddress["city"] || "";
    this.stateTarget.value = selectedAddress["state_or_province"] || "";
    this.zipCodeTarget.value = selectedAddress["postal_code"] || "";
    this.notesTarget.value = selectedAddress["notes"] || "";
    this.commercialTarget.checked =
      selectedAddress["address_type"] == "commercial";
  }
}
