import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  update(event) {
    if (!event.target.checked) {
      this.checkboxTarget.checked = false;
    }
  }
}
