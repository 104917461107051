import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addItem", "template"];

  addAssociation(event) {
    let content = this.templateTarget.innerHTML.replace(
      /CHILD_INDEX/g,
      new Date().getTime(),
    );
    this.addItemTarget.insertAdjacentHTML("beforebegin", content);
  }

  removeAssociation(event) {
    let wrapper = event.target.closest(".nested-fields");

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }
  }
}
