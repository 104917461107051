import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    slideNumber: Number,
    maxItemNumber: Number,
  };

  connect() {
    setTimeout(() => {
      new Swiper(this.element, {
        slidesPerView: 2,
        breakpoints: {
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: this.maxItemNumberValue > 2 ? 3 : 2,
          },
          1000: {
            slidesPerView: this.maxItemNumberValue,
            centeredSlides: false,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        observer: true,
        centeredSlides: true,
        observeParents: true,
        initialSlide: this.slideNumberValue,
      });
    }, 250);
  }
}
