import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    frameName: String,
    newTarget: String,
    subAction: String,
  };

  update() {
    this.updateFrameTargetAndSubActionValues();
  }

  updateAndSubmit() {
    this.updateFrameTargetAndSubActionValues();
    this.formSubmit();
  }

  resetAndSubmit() {
    var subaction = document.getElementById("subaction");
    if (subaction !== null) {
      subaction.value = "";
    }
    this.formSubmit();
  }

  updateFrameTargetAndSubActionValues() {
    this.addHiddenSubActionInputs();
    var subaction = document.getElementById("subaction");

    document
      .getElementById(this.frameNameValue)
      .setAttribute("target", this.newTargetValue);

    if (this.subActionValue) {
      subaction.value = this.subActionValue;
    } else {
      subaction.value = "";
    }
  }

  addHiddenSubActionInputs() {
    if (document.getElementById("subaction") == null) {
      var elInput = document.createElement("input");
      elInput.setAttribute("type", "hidden");
      elInput.id = "subaction";
      elInput.setAttribute("name", "subaction");
      this.element.closest("form").appendChild(elInput);
    }
  }

  formSubmit() {
    var form = this.element.closest("form");
    if (form.requestSubmit) {
      form.requestSubmit();
    } else {
      form.submit();
    }
  }
}
