import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "subtotalSummary"];

  show(event) {
    event.preventDefault();
    const selectedItemTargets = this.itemTargets.filter(
      (checkbox) => checkbox.checked,
    );
    const params = this._buildRequestParams(selectedItemTargets);
    const url = event.target.href + "?" + new URLSearchParams(params);
    fetch(url)
      .then((response) => response.json())
      .then((res) => {
        this.subtotalSummaryTarget.innerHTML = res;
      });
  }

  _buildRequestParams(itemTargets) {
    return itemTargets.reduce((acc, itemTarget) => {
      acc[itemTarget.dataset.productSku] = itemTarget.dataset.quantity;
      return acc;
    }, {});
  }
  _groupBySummaryGroup(itemTargets) {
    return itemTargets.reduce((acc, itemTarget) => {
      const summaryGroup = itemTarget.dataset.subtotalSummaryGroup;
      (acc[summaryGroup] = acc[summaryGroup] || []).push(itemTarget);
      return acc;
    }, {});
  }
}
