export class WaldenMap {
  constructor(args) {
    this.element = args.element;
    this.origin = args.origin;
    this.polygons = {};
    this.polyColors = {
      selected: "#FFFFFF",
      default: "#FF0000",
    };
    this.polyLines = {};
    this.markers = {};
    this.pins = {
      share:
        "M31.688,2C47.428,2,59,13.989,59,29.729v0.896C59,51.367,41.119,77,31.212,83h-0.237  C21.069,77,2,51.367,2,30.625v-0.896C2,13.989,14.76,2,30.5,2C30.659,2,31.529,2,31.688,2z",
      zip: "M 400 300 L 350 300 L 300 300 L 300 350 L 350 400 L 400 350 L 400 300",
    };
  }

  render() {
    const mapOptions = {
      zoom: 10,
      center: new google.maps.LatLng(this.origin.lat, this.origin.lng),
      noClear: true,
    };
    this.map = new google.maps.Map(this.element, mapOptions);
    this.setMapStyle();
  }

  getRandomArrayElem(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  createPolygon(id, paths) {
    // const randColor = this.getRandomArrayElem(this.pinColors);
    const colors = this.polyColors;
    const poly = new google.maps.Polygon({
      paths: paths,
      strokeColor: colors.default,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: colors.default,
      fillOpacity: 0.35,
    });

    poly.setMap(this.map);
    this.polygons[id] = {
      poly: poly,
      label: null,
      color: colors.default,
    };
  }

  createPolyLine(id, path, color) {
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeColor: color,
      fillColor: color,
      strokeOpacity: 1,
      scale: 3,
    };
    const polyLine = new google.maps.Polyline({
      path: path,
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "15px",
        },
      ],
      zIndex: 9999,
      map: this.map,
    });
    if (this.polyLines[id]) {
      this.polyLines[id].setMap(null);
    }
    this.polyLines[id] = polyLine;
  }

  deletePolyLine(id) {
    const polyLine = this.polyLines[id];
    if (polyLine) {
      polyLine.setMap(null);
      delete this.polyLines[id];
    }
  }

  addPolygonLabel(id, position, label) {
    const poly = this.polygons[id].poly;
    if (poly) {
      const polyLabel = new google.maps.Marker({
        position: position,
        label: {
          text: label,
          fontSize: "16px",
          fontWeight: "bold",
        },
        map: this.map,
        icon: null,
      });
      polyLabel.setIcon({
        path: "M 0,-1 0,1",
        fillColor: "#000000",
        strokeColor: "#000000",
        fillOpacity: 0.9,
        scale: 0,
        zIndex: 9999,
      });
      this.polygons[id].label = polyLabel;
    }
  }

  removePolygonLabel(id) {
    const poly = this.polygons[id];
    if (poly) {
      const label = poly.label;
      if (label) {
        label.setMap(null);
        poly.label = null;
      }
    }
  }

  createMarker(id, position, color, title, type, label) {
    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
      title: title,
      icon: null,
    });
    if (type === "share") {
      marker.setIcon({
        path: this.pins.share,
        fillColor: color,
        strokeColor: color,
        fillOpacity: 0.9,
        scale: 0.25,
        zIndex: 9999,
      });
    } else if (type === "group") {
      if (label) {
        marker.setLabel({
          text: label,
          fontSize: "16px",
          fontWeight: "bold",
          color: "white",
          backgroundColor: color,
        });
      }
      marker.setIcon({
        path: "M 0,-1 0,1",
        fillColor: color,
        strokeColor: color,
        fillOpacity: 0.9,
        scale: 0.25,
        zIndex: 9998,
      });
    }

    if (this.markers[id]) {
      this.markers[id].setMap(null);
    }
    this.markers[id] = marker;
  }

  updatePolygonColor(id, color, set) {
    const poly = this.polygons[id];
    if (poly) {
      let clr = color ? color : poly.color;
      poly.poly.fillColor = clr;
      poly.poly.strokeColor = clr;
      if (set) {
        poly.color = clr;
      }
      // New way of doing this as of v3.4
      poly.poly.setOptions({
        fillColor: clr,
        storeColor: clr,
      });
      // Old way of doing this
      /*
       *poly.poly.setMap(null);
       *poly.poly.setMap(this.map);
       */
    }
  }

  deleteMarkers() {
    for (const markerId in this.markers) {
      const marker = this.markers[markerId];
      marker.setMap(null);
    }
    this.markers = {};
  }

  deleteMarker(id) {
    const marker = this.markers[id];
    if (marker) {
      marker.setMap(null);
      delete this.markers[id];
    }
  }

  deletePolygons() {
    for (const polyId in this.polygons) {
      const poly = this.polygons[polyId];
      if (poly.poly) {
        poly.poly.setMap(null);
      }
      if (poly.label) {
        poly.label.setMap(null);
      }
    }
    this.polygons = {};
  }

  deletePolyLines() {
    for (const polyLinelId in this.polyLines) {
      const polyLine = this.polyLines[polyLinelId];
      polyLine.setMap(null);
    }
    this.polyLines = {};
  }

  zoomTo(coords) {
    this.map.setCenter(coords);
    this.map.setZoom(12);
  }

  clear() {
    this.deletePolygons();
    this.deleteMarkers();
    this.deletePolyLines();
  }

  addListener(type, id, event, fn) {
    if (type === "polygon") {
      const poly = this.polygons[id].poly;
      if (poly) {
        poly.addListener(event, fn);
      }
    } else if (type === "marker") {
      const marker = this.markers[id];
      if (marker) {
        marker.addListener(event, fn);
      }
    }
  }

  setMapStyle() {
    this.map.setOptions({
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#181818",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1b1b1b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2c2c2c",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#8a8a8a",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#373737",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#3c3c3c",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [
            {
              color: "#4e4e4e",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d3d3d",
            },
          ],
        },
      ],
      disableDefaultUI: true,
    });
  }
}
