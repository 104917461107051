import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "selectionCheckbox",
    "selectionSummaryText",
    "quantitySelectField",
    "hiddenPurchasePolicyField",
    "subscriptionLabel",
    "hiddenQuantityField",
  ];

  quantitySelectFieldTargetConnected(qtySelectField) {
    const productSku = qtySelectField.dataset.productSku;
    this._setQtySelectFieldToHiddenQty(productSku, qtySelectField);
  }

  subscribeItem(event) {
    const checkbox = event.target;
    const productSku = checkbox.dataset.productSku;
    const purchasePolicyId = checkbox.dataset.purchasePolicyId;
    const subscriptionPurchasePolicyId =
      checkbox.dataset.subscriptionPurchasePolicyId;
    const checked = checkbox.checked;

    if (checked) {
      for (const hiddenPurchasePolicyField of this
        .hiddenPurchasePolicyFieldTargets) {
        if (hiddenPurchasePolicyField.dataset.productSku == productSku) {
          hiddenPurchasePolicyField.value = subscriptionPurchasePolicyId;
        }
      }
      for (const subscriptionLabel of this.subscriptionLabelTargets) {
        if (subscriptionLabel.dataset.productSku == productSku) {
          subscriptionLabel.classList.remove("hidden");
        }
      }
    } else {
      for (const hiddenPurchasePolicyField of this
        .hiddenPurchasePolicyFieldTargets) {
        if (hiddenPurchasePolicyField.dataset.productSku == productSku) {
          hiddenPurchasePolicyField.value = purchasePolicyId;
        }
      }

      for (const subscriptionLabel of this.subscriptionLabelTargets) {
        if (subscriptionLabel.dataset.productSku == productSku) {
          subscriptionLabel.classList.add("hidden");
        }
      }
    }
  }

  addItem(event) {
    const qtySelectField = event.target;
    const productSku = qtySelectField.dataset.productSku;
    const quantity = qtySelectField.options[qtySelectField.selectedIndex].value;
    if (quantity > 0) {
      this._showSelection(productSku, quantity);
    } else {
      this._hideSelection(productSku);
    }
    this._setHiddenQtyTo(productSku, quantity);
  }

  removeItem(event) {
    let productSku = event.target.dataset.productSku;
    for (const quantitySelectField of this.quantitySelectFieldTargets) {
      if (quantitySelectField.dataset.productSku == productSku) {
        quantitySelectField.options[0].selected = true;
      }
    }
    this._hideSelection(productSku);
    this._setHiddenQtyTo(productSku, 0);
  }

  _setQtySelectFieldToHiddenQty(productSku, qtySelectField) {
    for (const hiddenQuantityField of this.hiddenQuantityFieldTargets) {
      if (hiddenQuantityField.dataset.productSku == productSku) {
        this._setSelectFieldToValue(qtySelectField, hiddenQuantityField.value);
        break;
      }
    }
  }

  _setSelectFieldToValue(qtySelectField, rawVal) {
    const value = this._isBlank(rawVal) ? "0" : rawVal;
    let index = null;
    for (const option of qtySelectField.options) {
      if (option.value == value) {
        index = option.index;
        break;
      }
    }
    if (qtySelectField.options[index]) {
      qtySelectField.options[index].selected = true;
    }
  }

  _isBlank(val) {
    return !val;
  }

  _showSelection(productSku, quantity) {
    for (const selection of this.selectionCheckboxTargets) {
      if (selection.dataset.productSku == productSku) {
        this._updateQuantityShown(productSku, quantity);
        selection.checked = true;
        selection.dataset.quantity = quantity;
      }
    }
  }

  _updateQuantityShown(productSku, quantity) {
    for (const target of this.selectionSummaryTextTargets) {
      if (target.dataset.productSku == productSku) {
        let text = target.innerHTML;
        let updatedText = text
          .replace(/\([\d{0,2}]*\)/g, "()")
          .replace("()", `(${quantity})`);
        target.innerHTML = updatedText;
      }
    }
  }

  _hideSelection(productSku) {
    var eventDispatched = false;
    for (const selection of this.selectionCheckboxTargets) {
      if (selection.dataset.productSku == productSku) {
        selection.checked = false;
        selection.dataset.quantity = 0;

        if (!eventDispatched) {
          selection.dispatchEvent(new Event("click"));
          eventDispatched = true;
        }
      }
    }
  }

  _setHiddenQtyTo(productSku, value) {
    for (const hiddenQuantityField of this.hiddenQuantityFieldTargets) {
      if (hiddenQuantityField.dataset.productSku == productSku) {
        hiddenQuantityField.value = value;
      }
    }
  }
}
