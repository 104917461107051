import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    slideNumber: Number,
  };

  connect() {
    setTimeout(() => {
      new Swiper(this.element, {
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1000: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        observer: true,
        observeParents: true,
        spaceBetween: 20,
        initialSlide: this.slideNumberValue,
      });
    }, 250);
  }
}
