import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { intervalMs: Number, src: String };

  connect() {
    this._fetchAndRenderStreamMessage();

    setInterval(() => {
      this._fetchAndRenderStreamMessage();
    }, this.intervalMsValue);
  }

  _fetchAndRenderStreamMessage() {
    fetch(this.srcValue, { headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
