import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab"];

  changeTab(event) {
    event.preventDefault();
    const activeTabIndex = parseInt(event.currentTarget.dataset.goToTab);
    this._goToTab(activeTabIndex);
  }

  _goToTab(tabIndex) {
    const groupedTabTargets = this._groupByParent(this.tabTargets);
    Object.keys(groupedTabTargets).forEach((groupId) => {
      groupedTabTargets[groupId].forEach((tabTarget, index) => {
        if (index == tabIndex) {
          tabTarget.classList.remove("hidden");
        } else {
          tabTarget.classList.add("hidden");
        }
      });
    });
  }

  _groupByParent(tabTargets) {
    return tabTargets.reduce((acc, tabTarget) => {
      const key = tabTarget.parentElement.id;
      (acc[key] = acc[key] || []).push(tabTarget);
      return acc;
    }, {});
  }
}
