import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  change(e) {
    if (e.target.checked) {
      for (const checkboxTarget of this.checkboxTargets) {
        if (checkboxTarget != e.target) {
          checkboxTarget.checked = false;
        }
      }
    }
  }
}
