import { Controller } from "stimulus";
export default class extends Controller {
  static values = {
    minPointsVector: Array,
    maxPointsVector: Array,
    validationMessagesVector: Array,
    signUpV2: Boolean,
  };
  static targets = ["errorTemplate", "checkBox", "notice", "errorsContainer"];
  static outlets = ["form-validations"];

  connect() {
    this.revalidate();
  }

  revalidate() {
    let errorMessages = [];

    this._clearNotices();

    this.minPointsVectorValue.forEach((minPointsValue, index) => {
      let selectedPoints = 0;

      for (const checkbox of this.checkBoxTargets) {
        if (checkbox.checked) {
          const pointsValue = JSON.parse(checkbox.dataset.pointsVector)[index];
          selectedPoints += parseInt(pointsValue);
        }
      }

      const maxPointsValue = this.maxPointsVectorValue[index];

      if (selectedPoints < minPointsValue || selectedPoints > maxPointsValue) {
        if (this.signUpV2Value) {
          errorMessages.push(
            this._v2_validation_vector(selectedPoints, minPointsValue)[index],
          );
        } else {
          errorMessages.push(this.validationMessagesVectorValue[index]);
        }
      }
    });

    errorMessages.forEach((error) => {
      this._addNotice(error);
    });
    this.formValidationsOutlet.checkValidations();
  }

  _v2_validation_vector(selectedPoints, minPointsValue) {
    return [
      `${selectedPoints} of ${minPointsValue} selected`,
      `0 lamb items selected`,
    ];
  }

  _clearNotices() {
    this.noticeTargets.forEach((notice) => {
      notice.remove();
    });
  }

  _addNotice(error) {
    const notice =
      this.errorTemplateTarget.content.firstElementChild.cloneNode(true);
    notice.innerText = error;
    this.errorsContainerTarget.append(notice);
  }
}
