import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "address1Field",
    "address2Field",
    "cityField",
    "stateField",
    "zipField",
  ];

  connect() {
    setTimeout(() => {
      this.initAutcomplete();
    }, 250);
  }

  initAutcomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US
    this.autocomplete = new google.maps.places.Autocomplete(
      this.address1FieldTarget,
      {
        componentRestrictions: { country: ["us"] },
        fields: ["address_components"],
        types: ["address"],
      },
    );
    this.address1FieldTarget.focus();
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    this.autocomplete.addListener(
      "place_changed",
      this.fillInAddress.bind(this),
    );
  }

  fillInAddress() {
    let address1 = "";

    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          this.zipFieldTarget.value = component.long_name;
          break;
        }

        case "locality":
          this.cityFieldTarget.value = component.long_name;
          break;

        case "administrative_area_level_1": {
          this.stateFieldTarget.value = component.long_name;
          break;
        }
      }
    }
    this.address1FieldTarget.value = address1;
    this.address2FieldTarget.focus();
  }
}
