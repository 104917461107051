import { Controller } from "stimulus";

const KEYCODE_SPACE = 32;
const KEYCODE_ENTER = 13;
const KEYCODE_UP_ARROW = 38;
const KEYCODE_DOWN_ARROW = 40;
const KEYCODE_ESCAPE = 27;

export default class extends Controller {
  static targets = ["dropdownMenu", "mobileMenu"];

  connect() {
    // Hide open menus on any clicks outside of menus
    document.addEventListener("click", () => this._hideAllExcept(null), false);
  }

  toggleMenu(event) {
    event.stopPropagation();
    let menuTarget = this._dropdownMenuOf(event.currentTarget);
    if (menuTarget) {
      this._hideAllExcept(menuTarget);
      menuTarget.classList.toggle("hidden");
    }
  }

  navigateMenu(event) {
    let menuTarget = this._dropdownMenuOf(event.currentTarget);
    if (menuTarget === undefined) {
      return;
    }

    // SPACE/ENTER handling:
    if (event.keyCode === KEYCODE_SPACE || event.keyCode === KEYCODE_ENTER) {
      if (event.target !== event.currentTarget) {
        // Ignore SPACE/ENTER events that bubbled up to this element, as they are likely for children:
        return;
      }

      this._handleMenuSelect(menuTarget);
    }
    // UP / DOWN arrow handling:
    else if (
      event.keyCode === KEYCODE_DOWN_ARROW ||
      event.keyCode === KEYCODE_UP_ARROW
    ) {
      this._handleMenuUpDown(menuTarget, event.keyCode);
    }
    // ESC handling:
    else if (event.keyCode === KEYCODE_ESCAPE) {
      this._handleMenuEscape(menuTarget);
    }
  }

  toggleMobileMenu() {
    if (event.keyCode === KEYCODE_SPACE || event.keyCode === KEYCODE_ENTER) {
      event.preventDefault();
      this.mobileMenuTarget.classList.toggle("hidden");
    } else if (event.keyCode === undefined) {
      this.mobileMenuTarget.classList.toggle("hidden");
    }
  }

  _dropdownMenuOf(toggleButton) {
    for (const target of this.dropdownMenuTargets) {
      if (toggleButton === target.parentElement) {
        return target;
      }
    }
  }

  _hideAllExcept(exceptTarget) {
    for (const target of this.dropdownMenuTargets) {
      if (target !== exceptTarget) {
        target.classList.add("hidden");
      }
    }
  }

  _handleMenuSelect(menuTarget) {
    event.preventDefault();
    if (menuTarget.classList.contains("hidden")) {
      this._hideAllExcept(menuTarget);
      menuTarget.classList.remove("hidden");
      menuTarget.children[0].focus();
    } else {
      menuTarget.classList.add("hidden");
    }
  }

  _handleMenuUpDown(menuTarget, keyCode) {
    event.preventDefault();
    if (menuTarget.classList.contains("hidden")) {
      // If hidden, show and select last element if up arrow, first element if down arrow:
      this._hideAllExcept(menuTarget);
      menuTarget.classList.remove("hidden");
      menuTarget.children[
        keyCode === KEYCODE_UP_ARROW ? menuTarget.children.length - 1 : 0
      ].focus();
    } else {
      // If visible, use up/down to select previous/next element:

      // Find index of currently focused child:
      let focusedChildIndex = (() => {
        for (
          let childIndex = 0;
          childIndex < menuTarget.children.length;
          childIndex++
        ) {
          if (menuTarget.children[childIndex] === document.activeElement) {
            return childIndex;
          }
        }
        return -1;
      })();

      // Find next child to focus based on which arrow key pressed and index of currently focused child:
      let nextFocusedChildIndex =
        keyCode === KEYCODE_UP_ARROW
          ? focusedChildIndex - 1
          : focusedChildIndex + 1;
      if (nextFocusedChildIndex < 0) {
        nextFocusedChildIndex = menuTarget.children.length - 1;
      }
      if (nextFocusedChildIndex >= menuTarget.children.length) {
        nextFocusedChildIndex = 0;
      }
      menuTarget.children[nextFocusedChildIndex].focus();
    }
  }

  _handleMenuEscape(menuTarget) {
    if (!menuTarget.classList.contains("hidden")) {
      menuTarget.classList.add("hidden");
      menuTarget.parentElement.focus();
    }
  }
}
